import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import Select from "../../../../components/shared/InputElements/Select";
import { useIntl } from "react-intl";

const SelectElectricityProvider = ({
  id = "electricity-provider",
  location,
  ...rest
}) => {
  let power_suppliers = ["All"];
  if (location) {
    location.power_supplier.forEach((spot) => {
      if (spot.name === "DTE Energy") return;
      power_suppliers.push(spot.name);
    });
  }
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({
    id: "incentives.electricityProvider",
    defaultMessage: "Electricity Provider",
  });
  let defaultElectricityProviderValue = userPrefs.get("electricityProvider").toString();
  // if(power_suppliers[1]) {
  //      defaultElectricityProviderValue = power_suppliers[1];
  // }

  return (
    <Select
      id={id}
      isSpecial={true}
      value={defaultElectricityProviderValue}
      label={label}
      optionNames={power_suppliers.length >= 1 ? power_suppliers : ["None"]}
      optionValues={power_suppliers.length >= 1 ? power_suppliers : ["None"]}
      handler={(e) => userPrefs.set({ electricityProvider: e.target.value })}
       isRequired={true}
    />
  );
};

export default SelectElectricityProvider;

SelectElectricityProvider.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
};
