import React, { useEffect } from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "../../client_customizations/components/IncentiveCatalog/IncentiveCatalog";
import IncentiveSortControls from "../../client_customizations/components/IncentiveSortControls/IncentiveSortControls";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage } from "react-intl";
import IncentiveScoreOptions from "../../client_customizations/components/IncentiveScoreOptions/IncentiveScoreOptions";
import IncentiveVehicleOptions from "../../client_customizations/components/IncentiveVehicleOptions/IncentiveVehicleOptions";
import "./Incentives.scss";

const Incentives = ({ electricVehicles, incentives, location, postcode, ip, uuid }) => {
  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );
  const renderOptions = (
    <>
      <IncentiveScoreOptions location={location} postcode={postcode} />
      <br />
    </>
  );
  const renderVehicleOptions = electricVehicles ? (
    <IncentiveVehicleOptions electricVehicles={electricVehicles} />
  ) : null;
  return (
    <section className="ev-container-fluid" id="Incentives">
      <div className="row mb-3">
        <div className="col-sm-12 text-center">
          <h1 className="evHead">
            <FormattedMessage
              id="incentives.title.alt"
              defaultMessage="Electric vehicle and charger incentives "
              description="Electric vehicle and charger incentives "
            />
          </h1>
          <p className="lead" style={{ paddingTop: 0}}>
            <FormattedMessage
              id="evs.incentives.subTitle"
              defaultMessage="Save money when you buy an electric vehicle! You may be eligible for a range of incentives, including EV rebates, EV tax credits, toll discounts, HOV lane access, and other benefits. Adjust the zip code, income, and other filters to personalize your results."
              description="Save money when you buy an electric vehicle! You may be eligible for a range of incentives, including EV rebates, EV tax credits, toll discounts, HOV lane access, and other benefits. Adjust the zip code, income, and other filters to personalize your results."
              values={{
                lineBreak: <br />,
              }}
            />
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="pull-right">
            <IncentiveSortControls />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="filterCol">
          {renderOptions}
          {renderVehicleOptions}
          <div className="incentives-disclaimer">
              <p>Adjust filters to see potential eligibility for EV rebates, tax credits, and various other perks offered by federal, state, utility, and other local grantors. Exact eligibility may vary based on numerous factors. Please consult the incentive grantor and/or your tax professional for the most accurate information.</p>
          </div>
        </div>
        <div className="contentCol" style={{ paddingLeft: 10 }}>
          {renderIncentives}
        </div>
      </div>
    </section>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string,
};
