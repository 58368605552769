import React from 'react';
import IframeEmbed from './IframeEmbed';
import './Analytics.scss';

function Analytics() {
  const iframeSrc =
    process.env.REACT_APP_ENVIRONMENT === 'staging'
      ? process.env.REACT_APP_ANALYTICS_STAGING
      : process.env.REACT_APP_ANALYTICS_PRODUCTION;

  return (
    <div className='App'>
      <IframeEmbed src={iframeSrc} />
    </div>
  );
}

export default Analytics;