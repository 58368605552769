import React from 'react'
import "./Analytics.scss";
const IframeEmbed = ({ src }) => {
  return (
    <div className='iframe-container'>
      {' '}
      <iframe src={src} title='Embedded Content'></iframe>{' '}
    </div>
  )
}
export default IframeEmbed;

