import React, { Component } from "react";
import "url-search-params-polyfill";
import { Switch, Route, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./Root.scss";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import PageLayout from "../../components/PageLayout/PageLayout";
import "./../../../pages/EVs/EVs.scss";

import Analytics from "../../components/analytics/analytics";

import incentivesScreenshot from "../../assets/images/metaImages/incentives-screenshot.png";

// Pages
import Incentives from "../../../pages/Incentives/Incentives";
import FourOhFour from "../../../pages/FourOhFour/FourOhFour";

// Services
import fetchElectricVehicles from "../../../services/fetchElectricVehicles";
import fetchGasolineVehicles from "../../../services/fetchGasolineVehicles";
import fetchIncentives from "../../../services/fetchIncentives";
import fetchVehicleIncentivesWithHandle from "../../../services/fetchVehicleIncentivesWithHandle";

// Utilities
import Uuid from "../../../utils/Uuid/Uuid";
import {
  loadState,
  persistState,
} from "../../../utils/LocalStorage/LocalStorage";
import loadUserPrefs from "../../../context/UserPrefs/loadUserPrefs";
import getUserPref from "../../../context/UserPrefs/getUserPref";
import USER_PREF_PRESETS from "../../../context/UserPrefs/USER_PREF_PRESETS";
import GaTracker from "../../../utils/GaTracker/GaTracker";

class Root extends Component {
  constructor(props) {
    super(props);
    const existingState = loadState() || {};
    const savedPrefs =
      existingState && existingState.userPreferences
        ? existingState.userPreferences
        : {};

    if (!savedPrefs.vehicleFormFactorFilters)
      savedPrefs.vehicleFormFactorFilters = {};
    if (!savedPrefs.vehicleFuelTypeFilters)
      savedPrefs.vehicleFuelTypeFilters = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};
    if (!savedPrefs.chargerFormFactorFilters)
      savedPrefs.chargerFormFactorFilters = {};
    if (!savedPrefs.chargerWifiFilter) savedPrefs.chargerWifiFilter = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};

    this.state = {
      uuid: existingState.uuid || Uuid(),
      ipData: existingState.ipData || null,
      electricVehicles: null,
      usedElectricVehicles: null,
      gasolineVehicles: null,
      incentives: null,
      incentivePrefsModalIsOpen: false,
      userLocation: null,
      userLocationNotFound: false,
      userLocationDealersNotFound: false,
      zipcodeUpdating: false,
      homeChargers: null,
      userPreferences: loadUserPrefs(savedPrefs),
    };

    // Last resort to ensure that the user has a UUID
    if (!this.state.uuid) this.state.uuid = Uuid();
    this.loadElectricVehicleData = this.loadElectricVehicleData.bind(this);
    this.loadGasolineVehicleData = this.loadGasolineVehicleData.bind(this);
    this.loadIncentivesData = this.loadIncentivesData.bind(this);
    this.updateUserPreferences = this.updateUserPreferences.bind(this);
    this.incentivePrefsModalToggle = this.incentivePrefsModalToggle.bind(this);

    this._cachedPostcode = null;
    this._cachedUserPreferences = null;

  }

  componentDidMount() {
    GaTracker.initialize();
    const params = new URLSearchParams(this.props.location.search);
    const hashid = params.get('hashid');
    const userId = hashid ? hashid : this.state.uuid;
    const page = this.props.location.pathname + this.props.location.search;
    GaTracker.trackPage(page, { userId: userId });
    //     this.loadZipcodeData();
    this.loadIncentivesData();
    this._cachedPostcode = this.getUserZip();
    this._cachedUserPreferences = this.state.userPreferences;
  }

  // TODO: this should be cleaner
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.userPreferences.zipcode &&
      (
        this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode ||
        this.state.userPreferences.householdSize !== prevState.userPreferences.householdSize ||
        this.state.userPreferences.householdIncome !== prevState.userPreferences.householdIncome ||
        this.state.userPreferences.canTurnInClunker !== prevState.userPreferences.canTurnInClunker ||
        this.state.userPreferences.taxFilingStatus !== prevState.userPreferences.taxFilingStatus
      )
    ) {
      this.loadIncentivesData();
      this._cachedPostcode = this.getUserZip();
      this._cachedUserPreferences = this.state.userPreferences;
    } else if (
      this.state.userPreferences.vehicleIdForIncentives !==
      prevState.userPreferences.vehicleIdForIncentives ||
      this.state.userPreferences.vehicleHandleForIncentives !==
      prevState.userPreferences.vehicleHandleForIncentives ||
      this.state.userPreferences.electricityProvider !==
      prevState.userPreferences.electricityProvider
    ) {
      this.loadIncentivesData();
      this._cachedPostcode = this.getUserZip();
      this._cachedUserPreferences = this.state.userPreferences;
    }

    //     if (
    //       this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode
    //     ) {
    //       this.loadZipcodeData();
    //     }

    if (!this.state.userLocationNotFound && !this.state.zipcodeUpdating) {
      persistState(this.state);
    }

    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const nextPage = this.props.location.pathname + this.props.location.search;

    if (currentPage !== nextPage) {
      const params = new URLSearchParams(this.props.location.search);
      const hashid = params.get('hashid');
      const userId = hashid ? hashid : this.state.uuid;
      GaTracker.trackPage(nextPage, { userId: userId });
    }

    if (this.props.language !== prevProps.language) {
      this.loadIncentivesData();
      this.loadElectricVehicleData();
      this._cachedPostcode = this.getUserZip();
      this._cachedUserPreferences = this.state.userPreferences;
    }
  }

  async loadElectricVehicleData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref(
        "householdIncome",
        this.state.userPreferences
      ),
    };

    try {
      const electricVehicles = await fetchElectricVehicles(params);
      if (!electricVehicles) return;
      this.setState({
        electricVehicles: electricVehicles.newElectricVehicles,
        usedElectricVehicles: electricVehicles.usedElectricVehicles,
      });
    } catch (e) {
      // TODO: handle errors here
    }
  }

  async loadGasolineVehicleData() {
    let params = {
      fuel_type: "gas",
      postcode: getUserPref("workingZipcode", this.state.userPreferences),
    };

    if (params.postcode === '' || params.fuel_type === '') {
      return;
    }

    try {
      const gasolineVehicles = await fetchGasolineVehicles(params);
      if (!gasolineVehicles) return;
      this.setState({ gasolineVehicles });
    } catch (e) { }
  }


  async loadIncentivesData() {
    let params = {
      postcode: this.getUserZip(),
      vehicle_handle: getUserPref(
        "vehicleHandleForIncentives",
        this.state.userPreferences
      ),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref(
        "householdIncome",
        this.state.userPreferences
      ),
      turn_in_clunker: getUserPref(
        "canTurnInClunker",
        this.state.userPreferences
      )
        ? true
        : null,
      tax_filing_type: getUserPref(
        "taxFilingStatus",
        this.state.userPreferences
      ),
    };

    if (getUserPref("electricityProvider", this.state.userPreferences) !== "All") {
      params.grantor = getUserPref(
        "electricityProvider",
        this.state.userPreferences
      );
    }

    let locationUrl = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/location`);
    let searchParams = new URLSearchParams(params);
    locationUrl.search = searchParams;

    // Create an empty JSON object
    let jsonParams = {};
    let jsonAddlParams = {};
    for (let key in params) {
      if (params.hasOwnProperty(key) && params[key] !== null && params[key] !== undefined) {
        if (key !== 'vehicle_handle' && key !== 'grantor' && key !== 'turn_in_clunker' && key !== 'distance') {
          jsonParams[key] = params[key];
        } else {
          jsonAddlParams[key] = params[key];
        }
      }
    }

    // Convert jsonParams to a JSON string
    let jsonParamsString = JSON.stringify(jsonParams);
    GaTracker.trackEvent("IncentiveFilter", "IncentiveFilter", jsonParamsString, window.location.pathname);
    GaTracker.trackEvent("IncentiveAdditionallFilter", "IncentiveAdditionallFilter", JSON.stringify(jsonAddlParams), window.location.pathname);

    try {
      let newPrefs;
      window.fetch(locationUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
        },
      })
        .then((response) => {
          if (response.status === 400) {
            this.setState({
              userLocationNotFound: true,
              zipcodeUpdating: false,
            });
          }
          return response;
        })
        .then((response) => response.json())
        .then((data) => {
          if (data.location) {
            //  let userLocation = data.location;
            this.setState({
              userLocation: data.location,
              userLocationNotFound: false,
              zipcodeUpdating: false,
            });
            newPrefs = {
              zipcode: data.location.postcode,
              country: data?.location?.country,
              municipality: data?.location?.region,
              salesTax: data?.location?.regional_financial_references?.[0]?.sales_tax?.region / 100.0,
              gasolinePriceInCentsPerGal: data?.location?.regional_fuel_cost[0]?.gasoline ? (data.location.regional_fuel_cost[0].gasoline * 100).toFixed(0) : this.state.userPreferences.gasolinePriceInCentsPerGal,
            };
            process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE && (newPrefs.electricityRate = data?.location?.regional_fuel_cost[0]?.electricity
              ? (
                data.location.regional_fuel_cost[0]?.electricity / 100
              ).toFixed(4)
              : this.state.userPreferences.electricityRate);
            this.setState({ newPrefs });
            this.incentivePrefsModalToggle(false);
          }
        });
      const incentives = params["vehicle_handle"] !== "" ? await fetchVehicleIncentivesWithHandle(params) : await fetchIncentives(params);
      if (!incentives) return;
      this.setState({ ...newPrefs, incentives });
    } catch (e) {
      // TODO: handle error
    }
  }

  getUserZip() {
    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences) || USER_PREF_PRESETS["zipcode"]
    };
    const workingZipcode = getUserPref("workingZipcode", this.state.userPreferences);

    if (workingZipcode) {
      params.postcode = workingZipcode;
    } else {
        const target = "post-code";
        const match = document.cookie.match(new RegExp("(^| )" + target + "=([^;]+)"));
        if (match) {
          params.postcode = match[2];
          this.updateUserPreferences({ zipcode: match[2] });
        }
    }
    return params.postcode;
  }
  loadZipcodeData() {
    let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/location`);
    let searchParams = new URLSearchParams(this.getUserZip());
    url.search = searchParams;

    window
      .fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
        },
      })
      .then((response) => {
        if (response.status === 400) {
          this.setState({
            userLocationNotFound: true,
            zipcodeUpdating: false,
          });
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.location) {
          this.setState({
            userLocation: data.location,
            userLocationNotFound: false,
            zipcodeUpdating: false,
          });
          let newPrefs = {
            zipcode: data.location.postcode,
            country: data?.location?.country,
            municipality: data?.location?.region,
            salesTax: data?.location?.regional_financial_references?.[0]?.sales_tax?.region / 100.0,
            gasolinePriceInCentsPerGal: data?.location?.regional_fuel_cost[0]
              ?.gasoline
              ? (data.location.regional_fuel_cost[0].gasoline * 100).toFixed(0)
              : this.state.userPreferences.gasolinePriceInCentsPerGal,
          };
          process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE &&
            (newPrefs.electricityRate = data?.location?.regional_fuel_cost[0]
              ?.electricity
              ? (
                data.location.regional_fuel_cost[0]?.electricity / 100
              ).toFixed(4)
              : this.state.userPreferences.electricityRate);
          this.updateUserPreferences(newPrefs);
          this.incentivePrefsModalToggle(false);
        }
      });
  }

  updateUserPreferences(newPrefs) {
    let prefs = Object.assign({}, this.state.userPreferences, newPrefs);

    let newState = {
      userPreferences: prefs,
    };
    if (
      newPrefs.zipcode &&
      this.state.userPreferences.zipcode !== newPrefs.zipcode
    )
      newState.zipcodeUpdating = true;
    this.setState(newState);
  }

  incentivePrefsModalToggle(override) {
    if (typeof override === "boolean") {
      this.setState({ incentivePrefsModalIsOpen: override });
    } else {
      this.setState({
        incentivePrefsModalIsOpen: !this.state.incentivePrefsModalIsOpen,
      });
    }
  }

  render() {
    const ip = this.state.ipData ? this.state.ipData.ip : null;
    const uuid = this.state.uuid;
    const language = this.props.language;
    const changeLanguage = this.props.changeLanguage;

    const userPrefs = {
      get: (key) => getUserPref(key, this.state.userPreferences),
      getPreset: (key) => USER_PREF_PRESETS[key],
      set: this.updateUserPreferences,
      zipcodeIsNotFound: this.state.userLocationNotFound,
      zipcodeIsUpdating: this.state.zipcodeUpdating,
      showIncentivePrefsModal: this.state.incentivePrefsModalIsOpen,
      toggleIncentivePrefsModal: this.incentivePrefsModalToggle,
      syncWorkingZipcode: () =>
        this.updateUserPreferences({
          zipcode: getUserPref("workingZipcode", this.state.userPreferences),
          electricityProvider: "All"
        }),
    };


    return (
      <UserPrefsContext.Provider value={userPrefs}>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => {
              return (
                <PageLayout
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  page="incentives"
                  description=""
                  disclaimers="Listed incentives may not be available at any given time. Listed incentives reflect an illustrative estimation of available incentives. {process.env.REACT_APP_FULL_COMPANY_NAME} does not recommend or endorse any particular automotive or insurance company."
                  title={"Save, Earn &amp; Live Better with Our Dedicated Nonprofit Organization | WorkMoney"}
                  image={incentivesScreenshot}
                >
                  <Incentives
                    {...props}
                    electricVehicles={this.state.electricVehicles}
                    incentives={this.state.incentives}
                    location={this.state.userLocation}
                    postcode={this._cachedPostcode}
                    ip={ip}
                    uuid={uuid}
                  />
                </PageLayout>
              );
            }}
          />
          <Route
            exact
            path="/analytics"
            render={(props) => {
              return (
                <PageLayout
                  language={language}
                  changeLanguage={changeLanguage}
                  props={props}
                  ip={ip}
                  uuid={uuid}
                  page="incentives"
                  description=""
                  disclaimers="Listed incentives may not be available at any given time. Listed incentives reflect an illustrative estimation of available incentives. {process.env.REACT_APP_FULL_COMPANY_NAME} does not recommend or endorse any particular automotive or insurance company."
                  title={"Save, Earn &amp; Live Better with Our Dedicated Nonprofit Organization | WorkMoney"}
                  image={incentivesScreenshot}
                >
                  <Analytics />

                </PageLayout>
              );
            }}
          />

          <Route component={FourOhFour} />
        </Switch>
      </UserPrefsContext.Provider>
    );
  }
}

export default withRouter(Root);
