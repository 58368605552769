import React from "react";
import "./Footer.scss";
import logo from "../../../assets/images/logo.svg";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";
const Footer = () => {
       return (
              <div className="Footer">
                     <div className="container">
                            <div className="logo-container">
                                   <a
                                          href="https://workmoney.org/?utm_source=zappy"
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="logo"
                                   >
                                          <img src={logo} alt="WorkMoney" />
                                   </a>
                                   <a className="join-link" href="https://workmoney.org/user/register">Join</a>
                            </div>

                            <div className="footer-nav-container">
                                   <div className="footer-nav">
                                          <ul>
                                                 <li>
                                                        <a href="https://workmoney.org/about-us">About Us</a>
                                                 </li>
                                                 <li>
                                                        <a href="https://workmoney.org/terms/sms-terms-of-service">SMS Terms of Service</a>
                                                 </li>
                                                 <li>
                                                        <a href="https://workmoney.org/terms/privacy-policy">Privacy</a>
                                                 </li>
                                                 <li>
                                                        <a href="https://apply.workable.com/workmoney/" target="_blank" rel="noopener noreferrer">Careers</a>
                                                 </li>
                                                 <li>
                                                        <a href="https://workmoney.org/terms/user-release">User Release</a>
                                                 </li>
                                                 <li>
                                                        <a href="https://workmoney.org/terms/product-disclaimer">Product Disclaimer</a>
                                                 </li>
                                          </ul>
                                   </div>
                                   <div className="footer-social">
                                          <ul>
                                                 <li>
                                                        <a href="https://www.facebook.com/WorkMoney.org" target="_blank" rel="noopener noreferrer">
                                                               <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" viewBox="0 0 310 310">
                                                                      <g id="XMLID_834_">
                                                                             <path id="XMLID_835_" d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
                                                                                    c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
                                                                                    V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
                                                                                    C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
                                                                                    c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"/>
                                                                      </g>
                                                               </svg>
                                                        </a>
                                                 </li>
                                                 <li>
                                                        <a href="https://www.twitter.com/workmoney" target="_blank" rel="noopener noreferrer">
                                                               <svg fill="#000000" height="800px" width="800px" version="1.1" id="Layer_1" viewBox="0 0 310 310">
                                                                      <g id="XMLID_826_">
                                                                             <path id="XMLID_827_" d="M302.973,57.388c-4.87,2.16-9.877,3.983-14.993,5.463c6.057-6.85,10.675-14.91,13.494-23.73
                                                                                    c0.632-1.977-0.023-4.141-1.648-5.434c-1.623-1.294-3.878-1.449-5.665-0.39c-10.865,6.444-22.587,11.075-34.878,13.783
                                                                                    c-12.381-12.098-29.197-18.983-46.581-18.983c-36.695,0-66.549,29.853-66.549,66.547c0,2.89,0.183,5.764,0.545,8.598
                                                                                    C101.163,99.244,58.83,76.863,29.76,41.204c-1.036-1.271-2.632-1.956-4.266-1.825c-1.635,0.128-3.104,1.05-3.93,2.467
                                                                                    c-5.896,10.117-9.013,21.688-9.013,33.461c0,16.035,5.725,31.249,15.838,43.137c-3.075-1.065-6.059-2.396-8.907-3.977
                                                                                    c-1.529-0.851-3.395-0.838-4.914,0.033c-1.52,0.871-2.473,2.473-2.513,4.224c-0.007,0.295-0.007,0.59-0.007,0.889
                                                                                    c0,23.935,12.882,45.484,32.577,57.229c-1.692-0.169-3.383-0.414-5.063-0.735c-1.732-0.331-3.513,0.276-4.681,1.597
                                                                                    c-1.17,1.32-1.557,3.16-1.018,4.84c7.29,22.76,26.059,39.501,48.749,44.605c-18.819,11.787-40.34,17.961-62.932,17.961
                                                                                    c-4.714,0-9.455-0.277-14.095-0.826c-2.305-0.274-4.509,1.087-5.294,3.279c-0.785,2.193,0.047,4.638,2.008,5.895
                                                                                    c29.023,18.609,62.582,28.445,97.047,28.445c67.754,0,110.139-31.95,133.764-58.753c29.46-33.421,46.356-77.658,46.356-121.367
                                                                                    c0-1.826-0.028-3.67-0.084-5.508c11.623-8.757,21.63-19.355,29.773-31.536c1.237-1.85,1.103-4.295-0.33-5.998
                                                                                    C307.394,57.037,305.009,56.486,302.973,57.388z"/>
                                                                      </g>
                                                               </svg>
                                                        </a>
                                                 </li>
                                                 <li>
                                                        <a href="https://www.instagram.com/workmoneyorg" target="_blank" rel="noopener noreferrer">
                                                               <svg fill="#000000"  version="1.1"  width="800px"
                                                               height="800px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512">
                                                                      <g id="5151e0c8492e5103c096af88a50059a5">
                                                                             <path display="inline" fillRule="evenodd" clipRule="evenodd" d="M66.084,0.5h379.819c36.079,0,65.597,29.505,65.597,65.584
                                                                                           v379.819c0,36.079-29.518,65.597-65.597,65.597H66.084C30.005,511.5,0.5,481.982,0.5,445.903V66.084
                                                                                           C0.5,30.005,30.005,0.5,66.084,0.5L66.084,0.5z M372.734,57.264c-12.65,0-23.005,10.355-23.005,23.005v55.067
                                                                                           c0,12.65,10.354,23.005,23.005,23.005h57.762c12.65,0,23.005-10.354,23.005-23.005V80.269c0-12.65-10.354-23.005-23.005-23.005
                                                                                           H372.734L372.734,57.264z M453.738,216.59h-44.975c4.254,13.897,6.55,28.606,6.55,43.852c0,84.996-71.111,153.898-158.839,153.898
                                                                                           c-87.716,0-158.827-68.902-158.827-153.898c0-15.245,2.295-29.954,6.55-43.852H57.276v215.853c0,11.178,9.132,20.322,20.311,20.322
                                                                                           h355.841c11.166,0,20.311-9.145,20.311-20.322V216.59L453.738,216.59z M256.475,155.447c-56.677,0-102.625,44.525-102.625,99.443
                                                                                           s45.948,99.443,102.625,99.443c56.688,0,102.636-44.525,102.636-99.443S313.163,155.447,256.475,155.447z">
                                                                             </path>
                                                                      </g>
                                                               </svg>
                                                        </a>
                                                 </li>
                                          </ul>
                                   </div>
                            </div>
                     </div>
                     <div className="footer-bottom">
                            <div className="container">
                                   <p className="copyright">&copy;2023 - WorkMoney</p>
                            </div>
                            <div className="disclaimer_container">
                 
                                   <DisclaimerComponent
                                   clientName="zappyride"
                                   textStyle={{
                                          fontFamily: "TT Norms Pro",
                                          fontSize: "14px",
                                          textAlign: "left",
                                          marginLeft:"154px"
                                   }}     
                                   imageStyle={{ marginLeft: "-350px",marginTop:"-20px" }}
                                   wrapperStyle={{
                                          padding: "0px",
                                   }}
                                   />
                            </div>
                     </div>
              </div>
       );
};

export default Footer;
