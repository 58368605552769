import GoogleAnalytics from "react-ga4";

const trackers = [];

// Avoiding having trackers populate as a side effect of importing the module.
const hydrateTrackers = () => {
       if (trackers.length) {
              return;
       }

       switch (process.env.REACT_APP_ENVIRONMENT) {
              case "production":
                     if(process.env.REACT_APP_GA_CLIENT_PRODUCTION_TRACKING_ID) {
                            trackers.push({
                                   trackingId: process.env.REACT_APP_GA_CLIENT_PRODUCTION_TRACKING_ID,
                                   gaOptions: {
                                          name: process.env.REACT_APP_GA_CLIENT_PRODUCTION_TRACKING_OPTIONS_NAME,
                                   },
                            });
                     }
                     if(process.env.REACT_APP_GA_ZAPPYRIDE_PRODUCTION_TRACKING_ID) {
                            trackers.push({
                                   trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_PRODUCTION_TRACKING_ID,
                                   gaOptions: {
                                          name: process.env.REACT_APP_GA_ZAPPYRIDE_PRODUCTION_TRACKING_OPTIONS_NAME,
                                   },
                            });
                     }
              break;

              default:
                     if(process.env.REACT_APP_GA_ZAPPYRIDE_STAGING_TRACKING_ID) {      
                            trackers.push({
                                   trackingId: process.env.REACT_APP_GA_ZAPPYRIDE_STAGING_TRACKING_ID,
                                   gaOptions: {
                                          name: process.env.REACT_APP_GA_CLIENT_TRACKING_OPTIONS_NAME,
                                   },
                            });
                     }
                     if(process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_ID) {
                            trackers.push({
                                   trackingId: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_ID,
                                   gaOptions: {
                                          name: process.env.REACT_APP_GA_CLIENT_STAGING_TRACKING_OPTIONS_NAME,
                                   },
                            });
                     }
       }
};

hydrateTrackers();

const GaTracker = {
  initialize: () => {
    if (trackers.length) {
      GoogleAnalytics.initialize(trackers);
    }
  },

  getPageName: (page) => {
    const lastCharIndex = page.length - 1;

    return page.length > 1 && page.charAt(lastCharIndex) === "/"
      ? page.substring(0, lastCharIndex)
      : page;
  },

  // For testing purposes only.
  doHydrate: () => {
    hydrateTrackers();
    GaTracker.initialize();
  },
  trackPage: (page, options = {}) => {
    page = GaTracker.getPageName(page);

    if (trackers.length) {
      trackers.forEach((tracker) => {
        GoogleAnalytics.set(
          {
            page,
            ...options,
          },
          [tracker.gaOptions.name]
        );
        GoogleAnalytics.pageview(page, [tracker.gaOptions.name]);
      });
    }
  },
  trackEvent: (category,
    event_name,
    label,
    data) => {

    if (trackers.length) {
      trackers.forEach((tracker) => {

        let event_params = {
          category,
          label,
          ...data
        };

        GoogleAnalytics.event(event_name, event_params, [tracker.gaOptions.name]);

      });
    }
  }
};

export default GaTracker;
