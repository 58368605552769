import React, { useState }  from 'react';
import PropTypes from "prop-types";
import {
  Navbar,
} from "reactstrap";

import logo from "../../../assets/images/logo.svg";
import "./Header.scss";

const Header = ({ page }) => {
       const [isSidebarOpen, setIsSidebarOpen] = useState(false);
       const [menuOneOpen, setMenuOneOpen] = useState(false);
       const [menuTwoOpen, setMenuTwoOpen] = useState(false);

       const handleOutsideClick = (e) => {
              if (!e.target.closest('.sidebar-wrapper')) {
                     setIsSidebarOpen(false);
              }
       };

       return (
              <div className="headerWrapper">
                     <div className="Header">
                            <div className="container" style={{ padding: 0, maxWidth: "1224px" }}>
                                   <Navbar expand="lg">
                                          <div className="nav-button">
                                                 <div className="button" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                                        <span></span>
                                                        <span></span>
                                                        <span></span>
                                                 </div>
                                          </div>
                                          <div className="logo-container" style={{ paddingTop: "0.75rem", paddingBottom: "0.75rem", width: "180px" }}>
                                                 <a
                                                 href="https://workmoney.org/?utm_source=zappy"
                                                 target="_blank"
                                                 rel="noopener noreferrer"
                                                 className="navbar-brand"
                                                 >
                                                 <img src={logo} alt="WorkMoney" />
                                                 </a>
                                          </div>
                                          <nav className="main-menu">
                                                 <ul>
                                                        <li>
                                                               <a href="https://workmoney.org/save-and-earn">Save and Earn</a>
                                                        </li>
                                                        <li>
                                                               <a href="https://workmoney.org/money-tips">Money Tips</a>
                                                        </li>
                                                        <li>
                                                               <a href="https://workmoney.org/member-benefits">Member Benefits</a>
                                                        </li>
                                                 </ul>
                                          </nav>
                                          <div className="nav-right">
                                                 <a href="https://workmoney.org/user/register">Join</a>
                                          </div>
                                   </Navbar>
                            </div>


                            <div className={`sidebar-container ${isSidebarOpen ? 'open' : ''}`} onClick={handleOutsideClick}>
                                   <div className="sidebar-wrapper">
                                          <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
                                                 <div className="logo-container">
                                                        <img src={logo} alt="Sidebar logo" />
                                                        <button className="close-button" onClick={() => setIsSidebarOpen(false)}>X</button>
                                                 </div>
                                                 <nav>
                                                        <ul>
                                                               <li>
                                                                      <a href="https://workmoney.org/save-and-earn">Save and Earn</a>
                                                                      <button type="button" onClick={() => setMenuOneOpen(!menuOneOpen)}>
                                                                             <div>
                                                                                    <span>expand_more</span>
                                                                             </div>
                                                                      </button>
                                                                      <div className={`collapse ${menuOneOpen ? 'open' : ''}`} id="save-and-earn">
                                                                             <ul className='sub-menu'>
                                                                                    <li>
                                                                                           <div>
                                                                                                  <span id="arrow-circle-down">arrow_circle_down</span>
                                                                                           </div>
                                                                                           <a href="https://workmoney.org/save-and-earn/lower-debt">Lower Debt</a>
                                                                                    </li>
                                                                                    <li>
                                                                                           <div>
                                                                                                  <span id="health-and-safety">health_and_safety</span>
                                                                                           </div>
                                                                                           <a href="https://workmoney.org/save-and-earn/healthcare">Healthcare</a>
                                                                                    </li>
                                                                                    <li>
                                                                                           <div>
                                                                                                  <span id="house">house</span>
                                                                                           </div>
                                                                                           <a href="https://workmoney.org/save-and-earn/housing">Housing</a>
                                                                                    </li>
                                                                                    <li>
                                                                                           <div>
                                                                                                  <span id="light">light</span>
                                                                                           </div>
                                                                                           <a href="https://workmoney.org/save-and-earn/utilities">Utilities</a>
                                                                                    </li>
                                                                                    <li>
                                                                                           <div>
                                                                                                  <span id="restaurant">restaurant</span>
                                                                                           </div>
                                                                                           <a href="https://workmoney.org/save-and-earn/food">Food</a>
                                                                                    </li>
                                                                                    <li>
                                                                                           <div>
                                                                                                  <span id="commute">commute</span>
                                                                                           </div>
                                                                                           <a href="https://workmoney.org/save-and-earn/transit-car">Transit &amp; Car</a>
                                                                                    </li>
                                                                                    <li>
                                                                                           <div>
                                                                                                  <span id="diversity">diversity_1</span>
                                                                                           </div>
                                                                                           <a href="https://workmoney.org/save-and-earn/family-care">Family Care</a>
                                                                                    </li>
                                                                                    <li>
                                                                                           <div>
                                                                                                  <span id="local-activity">local_activity</span>
                                                                                           </div>
                                                                                           <a href="https://workmoney.org/save-and-earn/fun">Fun</a>
                                                                                    </li>
                                                                             </ul>
                                                                      </div>
                                                               </li>
                                                               <li>
                                                                      <a href="/money-tips">Money Tips</a>
                                                                      <button onClick={() => setMenuTwoOpen(!menuTwoOpen)}>
                                                                             <div>
                                                                                    <span className="material-icons-round ms-2">expand_more</span>
                                                                             </div>
                                                                      </button>
                                                                      <div className={`collapse ${menuTwoOpen ? 'open' : ''}`} id="money-tips">
                                                                             <ul className='sub-menu'>
                                                                                    <li>
                                                                                           <div>
                                                                                                  <span id="credit-score">credit_score</span>
                                                                                           </div>
                                                                                           <a href="https://workmoney.org/money-tips/debt-tips">Debt Tips</a>
                                                                                    </li>
                                                                                    <li>
                                                                                           <div>
                                                                                                  <span id="savings">savings</span>
                                                                                           </div>
                                                                                           <a href="https://workmoney.org/money-tips/daily-savings">Daily Savings</a>
                                                                                    </li>
                                                                             </ul>
                                                                      </div>
                                                                      </li>
                                                                      <li>
                                                                             <a href="https://workmoney.org/member-benefits">Member Benefits</a>
                                                                      </li>
                                                               </ul>
                                                 </nav>
                                          </div>
                                   </div>
                            </div>

              </div>
       </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string,
};
