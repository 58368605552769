import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import unique from "lodash/uniq";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import Select from "../../../../components/shared/InputElements/Select";
import { FormattedMessage } from "react-intl";

const SelectVehicleTrimFilter = ({
  id = "select-vehicle-trim-filter",
  vehicles,
  makeOption,
  modelOption,
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const [selectedValue, setSelectedValue] = useState();
  const label = (
    <FormattedMessage id="evs.year" defaultMessage="Year" description="Year" />
  );

  const vehicleTrimOptions = (vehicles, makeOption, modelOption) => {
    if (modelOption && makeOption) {
      // console.log("modelOption", modelOption, "makeOption", makeOption);
      vehicles = vehicles.filter((vehicle) => {
        return vehicle.make === makeOption;
      });

      vehicles = vehicles.filter((vehicle) => {
        return vehicle.model === modelOption;
      });

      vehicles.sort((a, b) => {
        return a.model_year - b.model_year;
      });

      const trims = unique(vehicles.map((ev) => ev.model_year));

      if (!trims.length && vehicles.length) trims.push(vehicles[0].model_year);
      return trims;
    }
  };

  const handleChange = (e) => {
    let sortedVehicles;

    if (e.target.value === "Default") {
      sortedVehicles = vehicles.filter(
        (vehicle) => vehicle.model === modelOption
      );
    } else {
      vehicles = vehicles.filter((vehicle) => {
        return vehicle.make === makeOption;
      });

      vehicles = vehicles.filter((vehicle) => {
        return vehicle.model === modelOption;
      });

      sortedVehicles = vehicles.filter(
        (vehicle) => vehicle.model_year === Number(e.target.value)
      );
    }

    setSelectedValue(e.target.value);
    if (sortedVehicles.length) {
      userPrefs.set({
        vehicleHandleForIncentives: sortedVehicles[0].handle,
      });
    }
  };

  return (
    <Select
      id={id}
      value={selectedValue}
      label={label}
      optionNames={[
        "Select Year",
        ...(vehicleTrimOptions(vehicles, makeOption, modelOption) || []),
      ]}
      optionValues={[
        "",
        ...(vehicleTrimOptions(vehicles, makeOption, modelOption) || []),
      ]}
      disabled={
        modelOption &&
        vehicleTrimOptions(vehicles, makeOption, modelOption).length > 0
          ? false
          : true
      }
      handler={(e) => handleChange(e)}
    />
  );
};

export default SelectVehicleTrimFilter;

SelectVehicleTrimFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
