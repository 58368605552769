import React, { useContext } from "react";
import PropTypes from "prop-types";
import unique from "lodash/uniq";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import Select from "../../shared/InputElements/Select";
import { useIntl } from "react-intl";

const SelectVehicleMakeFilter = ({
  id = "select-vehicle-make-filter",
  vehicles,
  ...rest
}) => {
  const intl = useIntl();
  const userPrefs = useContext(UserPrefsContext);
  const vehicleMakeOptions = unique((vehicles || []).map((ev) => ev.make));
  const label = intl.formatMessage
    ? intl.formatMessage({ id: "make", defaultMessage: "Make" })
    : "Make";

  const handleChange = (e) => {
    userPrefs.set({
      vehicleMakeFilter: e.target.value,
      vehicleModelFilter: "",
    });
  };

  return (
    <Select
      id={id}
      value={userPrefs.get("vehicleMakeFilter")}
      label={label}
      optionNames={[
        intl.formatMessage
          ? intl.formatMessage({ id: "all", defaultMessage: "All" })
          : "All",
        ...vehicleMakeOptions,
      ]}
      optionValues={["All", ...vehicleMakeOptions]}
      handler={(e) => handleChange(e)}
    />
  );
};

export default SelectVehicleMakeFilter;

SelectVehicleMakeFilter.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
};
